import {Component, EventEmitter, Output} from '@angular/core';
import {Group} from '../../../entities/group';
import {GroupService} from '../../../services/group.service';
import {Address} from '../../../entities/address';
import {LocationService} from '../../../services/location.service';
import {UserService} from "../../../../app/services/user.service";
import {LonLat} from "../../../entities/lonlat";

@Component({
    selector: 'app-new-group',
    templateUrl: './new-group.component.html',
    styleUrls: ['./new-group.component.scss']
})
export class NewGroupComponent {


    //languages = ['NL', 'FR', 'EN'];
    languages = [
        {
            "short": "ab",
            "name": "Abkhaz",
            "nativeName": "аҧсуа"
        }, {
            "short": "aa",
            "name": "Afar",
            "nativeName": "Afaraf"
        }, {
            "short": "af",
            "name": "Afrikaans",
            "nativeName": "Afrikaans"
        }, {
            "short": "ak",
            "name": "Akan",
            "nativeName": "Akan"
        }, {
            "short": "sq",
            "name": "Albanian",
            "nativeName": "Shqip"
        }, {
            "short": "am",
            "name": "Amharic",
            "nativeName": "አማርኛ"
        }, {
            "short": "ar",
            "name": "Arabic",
            "nativeName": "العربية"
        }, {
            "short": "an",
            "name": "Aragonese",
            "nativeName": "Aragonés"
        }, {
            "short": "hy",
            "name": "Armenian",
            "nativeName": "Հայերեն"
        }, {
            "short": "as",
            "name": "Assamese",
            "nativeName": "অসমীয়া"
        }, {
            "short": "av",
            "name": "Avaric",
            "nativeName": "авар мацӀ, магӀарул мацӀ"
        }, {
            "short": "ae",
            "name": "Avestan",
            "nativeName": "avesta"
        }, {
            "short": "ay",
            "name": "Aymara",
            "nativeName": "aymar aru"
        }, {
            "short": "az",
            "name": "Azerbaijani",
            "nativeName": "azərbaycan dili"
        }, {
            "short": "bm",
            "name": "Bambara",
            "nativeName": "bamanankan"
        }, {
            "short": "ba",
            "name": "Bashkir",
            "nativeName": "башҡорт теле"
        }, {
            "short": "eu",
            "name": "Basque",
            "nativeName": "euskara, euskera"
        }, {
            "short": "be",
            "name": "Belarusian",
            "nativeName": "Беларуская"
        }, {
            "short": "bn",
            "name": "Bengali",
            "nativeName": "বাংলা"
        }, {
            "short": "bh",
            "name": "Bihari",
            "nativeName": "भोजपुरी"
        }, {
            "short": "bi",
            "name": "Bislama",
            "nativeName": "Bislama"
        }, {
            "short": "bs",
            "name": "Bosnian",
            "nativeName": "bosanski jezik"
        }, {
            "short": "br",
            "name": "Breton",
            "nativeName": "brezhoneg"
        }, {
            "short": "bg",
            "name": "Bulgarian",
            "nativeName": "български език"
        }, {
            "short": "my",
            "name": "Burmese",
            "nativeName": "ဗမာစာ"
        }, {
            "short": "ca",
            "name": "Catalan; Valencian",
            "nativeName": "Català"
        }, {
            "short": "ch",
            "name": "Chamorro",
            "nativeName": "Chamoru"
        }, {
            "short": "ce",
            "name": "Chechen",
            "nativeName": "нохчийн мотт"
        }, {
            "short": "ny",
            "name": "Chichewa; Chewa; Nyanja",
            "nativeName": "chiCheŵa, chinyanja"
        }, {
            "short": "zh",
            "name": "Chinese",
            "nativeName": "中文 (Zhōngwén), 汉语, 漢語"
        }, {
            "short": "cv",
            "name": "Chuvash",
            "nativeName": "чӑваш чӗлхи"
        }, {
            "short": "kw",
            "name": "Cornish",
            "nativeName": "Kernewek"
        }, {
            "short": "co",
            "name": "Corsican",
            "nativeName": "corsu, lingua corsa"
        }, {
            "short": "cr",
            "name": "Cree",
            "nativeName": "ᓀᐦᐃᔭᐍᐏᐣ"
        }, {
            "short": "hr",
            "name": "Croatian",
            "nativeName": "hrvatski"
        }, {
            "short": "cs",
            "name": "Czech",
            "nativeName": "česky, čeština"
        }, {
            "short": "da",
            "name": "Danish",
            "nativeName": "dansk"
        }, {
            "short": "dv",
            "name": "Divehi; Dhivehi; Maldivian;",
            "nativeName": "ދިވެހި"
        }, {
            "short": "nl",
            "name": "Dutch",
            "nativeName": "Nederlands, Vlaams"
        }, {
            "short": "en",
            "name": "English",
            "nativeName": "English"
        }, {
            "short": "eo",
            "name": "Esperanto",
            "nativeName": "Esperanto"
        }, {
            "short": "et",
            "name": "Estonian",
            "nativeName": "eesti, eesti keel"
        }, {
            "short": "ee",
            "name": "Ewe",
            "nativeName": "Eʋegbe"
        }, {
            "short": "fo",
            "name": "Faroese",
            "nativeName": "føroyskt"
        }, {
            "short": "fj",
            "name": "Fijian",
            "nativeName": "vosa Vakaviti"
        }, {
            "short": "fi",
            "name": "Finnish",
            "nativeName": "suomi, suomen kieli"
        }, {
            "short": "fr",
            "name": "French",
            "nativeName": "français, langue française"
        }, {
            "short": "ff",
            "name": "Fula; Fulah; Pulaar; Pular",
            "nativeName": "Fulfulde, Pulaar, Pular"
        }, {
            "short": "gl",
            "name": "Galician",
            "nativeName": "Galego"
        }, {
            "short": "ka",
            "name": "Georgian",
            "nativeName": "ქართული"
        }, {
            "short": "de",
            "name": "German",
            "nativeName": "Deutsch"
        }, {
            "short": "el",
            "name": "Greek, Modern",
            "nativeName": "Ελληνικά"
        }, {
            "short": "gn",
            "name": "Guaraní",
            "nativeName": "Avañeẽ"
        }, {
            "short": "gu",
            "name": "Gujarati",
            "nativeName": "ગુજરાતી"
        }, {
            "short": "ht",
            "name": "Haitian; Haitian Creole",
            "nativeName": "Kreyòl ayisyen"
        }, {
            "short": "ha",
            "name": "Hausa",
            "nativeName": "Hausa, هَوُسَ"
        }, {
            "short": "he",
            "name": "Hebrew (modern)",
            "nativeName": "עברית"
        }, {
            "short": "hz",
            "name": "Herero",
            "nativeName": "Otjiherero"
        }, {
            "short": "hi",
            "name": "Hindi",
            "nativeName": "हिन्दी, हिंदी"
        }, {
            "short": "ho",
            "name": "Hiri Motu",
            "nativeName": "Hiri Motu"
        }, {
            "short": "hu",
            "name": "Hungarian",
            "nativeName": "Magyar"
        }, {
            "short": "ia",
            "name": "Interlingua",
            "nativeName": "Interlingua"
        }, {
            "short": "id",
            "name": "Indonesian",
            "nativeName": "Bahasa Indonesia"
        }, {
            "short": "ie",
            "name": "Interlingue",
            "nativeName": "Originally called Occidental; then Interlingue after WWII"
        }, {
            "short": "ga",
            "name": "Irish",
            "nativeName": "Gaeilge"
        }, {
            "short": "ig",
            "name": "Igbo",
            "nativeName": "Asụsụ Igbo"
        }, {
            "short": "ik",
            "name": "Inupiaq",
            "nativeName": "Iñupiaq, Iñupiatun"
        }, {
            "short": "io",
            "name": "Ido",
            "nativeName": "Ido"
        }, {
            "short": "is",
            "name": "Icelandic",
            "nativeName": "Íslenska"
        }, {
            "short": "it",
            "name": "Italian",
            "nativeName": "Italiano"
        }, {
            "short": "iu",
            "name": "Inuktitut",
            "nativeName": "ᐃᓄᒃᑎᑐᑦ"
        }, {
            "short": "ja",
            "name": "Japanese",
            "nativeName": "日本語 (にほんご／にっぽんご)"
        }, {
            "short": "jv",
            "name": "Javanese",
            "nativeName": "basa Jawa"
        }, {
            "short": "kl",
            "name": "Kalaallisut, Greenlandic",
            "nativeName": "kalaallisut, kalaallit oqaasii"
        }, {
            "short": "kn",
            "name": "Kannada",
            "nativeName": "ಕನ್ನಡ"
        }, {
            "short": "kr",
            "name": "Kanuri",
            "nativeName": "Kanuri"
        }, {
            "short": "ks",
            "name": "Kashmiri",
            "nativeName": "कश्मीरी, كشميري‎"
        }, {
            "short": "kk",
            "name": "Kazakh",
            "nativeName": "Қазақ тілі"
        }, {
            "short": "km",
            "name": "Khmer",
            "nativeName": "ភាសាខ្មែរ"
        }, {
            "short": "ki",
            "name": "Kikuyu, Gikuyu",
            "nativeName": "Gĩkũyũ"
        }, {
            "short": "rw",
            "name": "Kinyarwanda",
            "nativeName": "Ikinyarwanda"
        }, {
            "short": "ky",
            "name": "Kirghiz, Kyrgyz",
            "nativeName": "кыргыз тили"
        }, {
            "short": "kv",
            "name": "Komi",
            "nativeName": "коми кыв"
        }, {
            "short": "kg",
            "name": "Kongo",
            "nativeName": "KiKongo"
        }, {
            "short": "ko",
            "name": "Korean",
            "nativeName": "한국어 (韓國語), 조선말 (朝鮮語)"
        }, {
            "short": "ku",
            "name": "Kurdish",
            "nativeName": "Kurdî, كوردی‎"
        }, {
            "short": "kj",
            "name": "Kwanyama, Kuanyama",
            "nativeName": "Kuanyama"
        }, {
            "short": "la",
            "name": "Latin",
            "nativeName": "latine, lingua latina"
        }, {
            "short": "lb",
            "name": "Luxembourgish, Letzeburgesch",
            "nativeName": "Lëtzebuergesch"
        }, {
            "short": "lg",
            "name": "Luganda",
            "nativeName": "Luganda"
        }, {
            "short": "li",
            "name": "Limburgish, Limburgan, Limburger",
            "nativeName": "Limburgs"
        }, {
            "short": "ln",
            "name": "Lingala",
            "nativeName": "Lingála"
        }, {
            "short": "lo",
            "name": "Lao",
            "nativeName": "ພາສາລາວ"
        }, {
            "short": "lt",
            "name": "Lithuanian",
            "nativeName": "lietuvių kalba"
        }, {
            "short": "lu",
            "name": "Luba-Katanga",
            "nativeName": ""
        }, {
            "short": "lv",
            "name": "Latvian",
            "nativeName": "latviešu valoda"
        }, {
            "short": "gv",
            "name": "Manx",
            "nativeName": "Gaelg, Gailck"
        }, {
            "short": "mk",
            "name": "Macedonian",
            "nativeName": "македонски јазик"
        }, {
            "short": "mg",
            "name": "Malagasy",
            "nativeName": "Malagasy fiteny"
        }, {
            "short": "ms",
            "name": "Malay",
            "nativeName": "bahasa Melayu, بهاس ملايو‎"
        }, {
            "short": "ml",
            "name": "Malayalam",
            "nativeName": "മലയാളം"
        }, {
            "short": "mt",
            "name": "Maltese",
            "nativeName": "Malti"
        }, {
            "short": "mi",
            "name": "Māori",
            "nativeName": "te reo Māori"
        }, {
            "short": "mr",
            "name": "Marathi (Marāṭhī)",
            "nativeName": "मराठी"
        }, {
            "short": "mh",
            "name": "Marshallese",
            "nativeName": "Kajin M̧ajeļ"
        }, {
            "short": "mn",
            "name": "Mongolian",
            "nativeName": "монгол"
        }, {
            "short": "na",
            "name": "Nauru",
            "nativeName": "Ekakairũ Naoero"
        }, {
            "short": "nv",
            "name": "Navajo, Navaho",
            "nativeName": "Diné bizaad, Dinékʼehǰí"
        }, {
            "short": "nb",
            "name": "Norwegian Bokmål",
            "nativeName": "Norsk bokmål"
        }, {
            "short": "nd",
            "name": "North Ndebele",
            "nativeName": "isiNdebele"
        }, {
            "short": "ne",
            "name": "Nepali",
            "nativeName": "नेपाली"
        }, {
            "short": "ng",
            "name": "Ndonga",
            "nativeName": "Owambo"
        }, {
            "short": "nn",
            "name": "Norwegian Nynorsk",
            "nativeName": "Norsk nynorsk"
        }, {
            "short": "no",
            "name": "Norwegian",
            "nativeName": "Norsk"
        }, {
            "short": "ii",
            "name": "Nuosu",
            "nativeName": "ꆈꌠ꒿ Nuosuhxop"
        }, {
            "short": "nr",
            "name": "South Ndebele",
            "nativeName": "isiNdebele"
        }, {
            "short": "oc",
            "name": "Occitan",
            "nativeName": "Occitan"
        }, {
            "short": "oj",
            "name": "Ojibwe, Ojibwa",
            "nativeName": "ᐊᓂᔑᓈᐯᒧᐎᓐ"
        }, {
            "short": "cu",
            "name": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
            "nativeName": "ѩзыкъ словѣньскъ"
        }, {
            "short": "om",
            "name": "Oromo",
            "nativeName": "Afaan Oromoo"
        }, {
            "short": "or",
            "name": "Oriya",
            "nativeName": "ଓଡ଼ିଆ"
        }, {
            "short": "os",
            "name": "Ossetian, Ossetic",
            "nativeName": "ирон æвзаг"
        }, {
            "short": "pa",
            "name": "Panjabi, Punjabi",
            "nativeName": "ਪੰਜਾਬੀ, پنجابی‎"
        }, {
            "short": "pi",
            "name": "Pāli",
            "nativeName": "पाऴि"
        }, {
            "short": "fa",
            "name": "Persian",
            "nativeName": "فارسی"
        }, {
            "short": "pl",
            "name": "Polish",
            "nativeName": "polski"
        }, {
            "short": "ps",
            "name": "Pashto, Pushto",
            "nativeName": "پښتو"
        }, {
            "short": "pt",
            "name": "Portuguese",
            "nativeName": "Português"
        }, {
            "short": "qu",
            "name": "Quechua",
            "nativeName": "Runa Simi, Kichwa"
        }, {
            "short": "rm",
            "name": "Romansh",
            "nativeName": "rumantsch grischun"
        }, {
            "short": "rn",
            "name": "Kirundi",
            "nativeName": "kiRundi"
        }, {
            "short": "ro",
            "name": "Romanian, Moldavian, Moldovan",
            "nativeName": "română"
        }, {
            "short": "ru",
            "name": "Russian",
            "nativeName": "русский язык"
        }, {
            "short": "sa",
            "name": "Sanskrit (Saṁskṛta)",
            "nativeName": "संस्कृतम्"
        }, {
            "short": "sc",
            "name": "Sardinian",
            "nativeName": "sardu"
        }, {
            "short": "sd",
            "name": "Sindhi",
            "nativeName": "सिन्धी, سنڌي، سندھی‎"
        }, {
            "short": "se",
            "name": "Northern Sami",
            "nativeName": "Davvisámegiella"
        }, {
            "short": "sm",
            "name": "Samoan",
            "nativeName": "gagana faa Samoa"
        }, {
            "short": "sg",
            "name": "Sango",
            "nativeName": "yângâ tî sängö"
        }, {
            "short": "sr",
            "name": "Serbian",
            "nativeName": "српски језик"
        }, {
            "short": "gd",
            "name": "Scottish Gaelic; Gaelic",
            "nativeName": "Gàidhlig"
        }, {
            "short": "sn",
            "name": "Shona",
            "nativeName": "chiShona"
        }, {
            "short": "si",
            "name": "Sinhala, Sinhalese",
            "nativeName": "සිංහල"
        }, {
            "short": "sk",
            "name": "Slovak",
            "nativeName": "slovenčina"
        }, {
            "short": "sl",
            "name": "Slovene",
            "nativeName": "slovenščina"
        }, {
            "short": "so",
            "name": "Somali",
            "nativeName": "Soomaaliga, af Soomaali"
        }, {
            "short": "st",
            "name": "Southern Sotho",
            "nativeName": "Sesotho"
        }, {
            "short": "es",
            "name": "Spanish; Castilian",
            "nativeName": "español, castellano"
        }, {
            "short": "su",
            "name": "Sundanese",
            "nativeName": "Basa Sunda"
        }, {
            "short": "sw",
            "name": "Swahili",
            "nativeName": "Kiswahili"
        }, {
            "short": "ss",
            "name": "Swati",
            "nativeName": "SiSwati"
        }, {
            "short": "sv",
            "name": "Swedish",
            "nativeName": "svenska"
        }, {
            "short": "ta",
            "name": "Tamil",
            "nativeName": "தமிழ்"
        }, {
            "short": "te",
            "name": "Telugu",
            "nativeName": "తెలుగు"
        }, {
            "short": "tg",
            "name": "Tajik",
            "nativeName": "тоҷикӣ, toğikī, تاجیکی‎"
        }, {
            "short": "th",
            "name": "Thai",
            "nativeName": "ไทย"
        }, {
            "short": "ti",
            "name": "Tigrinya",
            "nativeName": "ትግርኛ"
        }, {
            "short": "bo",
            "name": "Tibetan Standard, Tibetan, Central",
            "nativeName": "བོད་ཡིག"
        }, {
            "short": "tk",
            "name": "Turkmen",
            "nativeName": "Türkmen, Түркмен"
        }, {
            "short": "tl",
            "name": "Tagalog",
            "nativeName": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
        }, {
            "short": "tn",
            "name": "Tswana",
            "nativeName": "Setswana"
        }, {
            "short": "to",
            "name": "Tonga (Tonga Islands)",
            "nativeName": "faka Tonga"
        }, {
            "short": "tr",
            "name": "Turkish",
            "nativeName": "Türkçe"
        }, {
            "short": "ts",
            "name": "Tsonga",
            "nativeName": "Xitsonga"
        }, {
            "short": "tt",
            "name": "Tatar",
            "nativeName": "татарча, tatarça, تاتارچا‎"
        }, {
            "short": "tw",
            "name": "Twi",
            "nativeName": "Twi"
        }, {
            "short": "ty",
            "name": "Tahitian",
            "nativeName": "Reo Tahiti"
        }, {
            "short": "ug",
            "name": "Uighur, Uyghur",
            "nativeName": "Uyƣurqə, ئۇيغۇرچە‎"
        }, {
            "short": "uk",
            "name": "Ukrainian",
            "nativeName": "українська"
        }, {
            "short": "ur",
            "name": "Urdu",
            "nativeName": "اردو"
        }, {
            "short": "uz",
            "name": "Uzbek",
            "nativeName": "zbek, Ўзбек, أۇزبېك‎"
        }, {
            "short": "ve",
            "name": "Venda",
            "nativeName": "Tshivenḓa"
        }, {
            "short": "vi",
            "name": "Vietnamese",
            "nativeName": "Tiếng Việt"
        }, {
            "short": "vo",
            "name": "Volapük",
            "nativeName": "Volapük"
        }, {
            "short": "wa",
            "name": "Walloon",
            "nativeName": "Walon"
        }, {
            "short": "cy",
            "name": "Welsh",
            "nativeName": "Cymraeg"
        }, {
            "short": "wo",
            "name": "Wolof",
            "nativeName": "Wollof"
        }, {
            "short": "fy",
            "name": "Western Frisian",
            "nativeName": "Frysk"
        }, {
            "short": "xh",
            "name": "Xhosa",
            "nativeName": "isiXhosa"
        }, {
            "short": "yi",
            "name": "Yiddish",
            "nativeName": "ייִדיש"
        }, {
            "short": "yo",
            "name": "Yoruba",
            "nativeName": "Yorùbá"
        }, {
            "short": "za",
            "name": "Zhuang, Chuang",
            "nativeName": "Saɯ cueŋƅ, Saw cuengh"
        }
    ];


    group: Group;
    address: Address;
    lonlat = new LonLat();
    language: string;


    @Output()
    visible = new EventEmitter<boolean>();


    constructor(private groupService: GroupService,
                private userService: UserService,
                private locationService: LocationService) {

        this.group = new Group();
    }


    save(property, value) {
        const self = this;


        switch (property) {
            case 'name':
                this.group.name = value;
                break;
            case 'description':
                this.group.description = value;
                break;
            case 'country':
                this.group.address.country = value;
                break;
            case 'zip':
                this.group.address.zip = value;
                break;
            case 'city':
                this.group.address.city = value;
                break;
            case 'street':
                this.group.address.street = value;
                break;
            case 'number':
                this.group.address.number = value;
                break;
            case 'language':
                this.group.language = value;
                break;
        }

        let address = this.group.address.country + '+' + this.group.address.zip + '+' + this.group.address.city + '+' + this.group.address.street + '+' + this.group.address.number;
        this.locationService.createLonLatFromLocation(address).take(1).subscribe(data => self.lonlat = data);
    }

    create() {
        const self = this;

        let uid = this.userService.getUserId();
        this.locationService.createAddressFromLonlat(this.lonlat).take(1).subscribe(data => {
            self.group.address = data;
            self.group.language = this.language;
            self.group.location = self.group.address.lonlat;
            console.log(self.group);

            if (self.group.name) {
                self.groupService.createGroup(self.group, uid);
            }
            this.close()
        });
    }

    close() {
        this.visible.emit(false);
    }

    public isDisabled() {
        return !(
            (this.group.name.length > 3) &&
            (this.group.address.street.length > 3) &&
            (this.group.address.city.length > 0) &&
            (this.lonlat.lon > 0));
    }
}
