import {Routes} from '@angular/router';
import {ChatboxComponent} from './components/chatbox/chatbox.component';
import {HomeComponent} from './components/home/home.component';

export const routes: Routes = [
    {
        path: 'chatbox',
        component: HomeComponent
    }
];
