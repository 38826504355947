import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LonLat} from '../entities/lonlat';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import {Address} from '../entities/address';
import {environment} from '../../../environments/environment';

@Injectable()
export class LocationService {

    public geolocation = new LonLat();
    public address: Address;
    public location = '';


    constructor(public httpClient: HttpClient) {

        const self = this;

        // ask for geolocation
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.geolocation = new LonLat({lon: position.coords.longitude, lat: position.coords.latitude});
                    self.setAddressFromLonlat(this.geolocation);
                },
                (error) => {
                    // console.log(error);
                });
        }

    }

    public getCurrentAddress() {
        if (this.location) {
            return this.Lonlat2Address(this.geolocation);
        }
    }

    public setAddressFromLonlat(lonlat: LonLat) {

        this.Lonlat2Address(lonlat).subscribe(address => {
            this.address = address;
            this.location = address.city ? address.city : address.country + ' ' + address.zip;
            return address;
        });

    }

    public setLonLatFromLocation(location: string) {

        this.location = location;
        this.Address2Lonlat(location).subscribe(lonlat => {
            this.geolocation = lonlat;
            return this.setAddressFromLonlat(this.geolocation);
        });

    }

    public Lonlat2Address(lonlat: LonLat): Observable<Address> {

        // console.log('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lonlat.lat + ',' + lonlat.lon + '&sensor=true&key=AIzaSyByvZ4CQP-YWDoRiatnQVlbtHat0ybE17M');

        return this.httpClient.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lonlat.lat + ',' + lonlat.lon + '&sensor=true&key=AIzaSyByvZ4CQP-YWDoRiatnQVlbtHat0ybE17M').pipe(
            map(data => {
                let address = new Address({});

                if (data['results'][0]) {
                    address = new Address(data['results'][0].address_components);
                }
                address.setLonlat(lonlat);

                // console.log(data, lonlat, address);
                return address;
            }));
    }

    private Address2Lonlat(location: string): Observable<LonLat> {

        return this.httpClient.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + location + '&sensor=true&key=AIzaSyByvZ4CQP-YWDoRiatnQVlbtHat0ybE17M').pipe(
            map(data => {
                if (data['results'] && data['results'][0]) {
                    return new LonLat({
                        lon: data['results'][0]['geometry']['location']['lng'],
                        lat: data['results'][0]['geometry']['location']['lat']
                    });
                } else {
                    return new LonLat({});
                }
            }));
    }


    public createAddressFromLonlat(lonlat: LonLat): Observable<Address> {
        return this.Lonlat2Address(lonlat);
    }

    public createLonLatFromLocation(location: string): Observable<LonLat> {
        return this.Address2Lonlat(location);
    }
}

