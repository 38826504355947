import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Injectable()
export class NotificationService {

    text: string;
    statusses = ['error', 'warning', 'info'];
    currentStatus: number;

    constructor(public snackBar: MatSnackBar) {
    }


    showError(text: string) {
        this.text = text;
        this.currentStatus = 0;

        this.snackBar.open(text, 'x', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top'
        });
    }
}
