import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialogComponent {

  public question =  "Are you sure you want to close this window? Any changes will be lost.";
  public okLabel = "Ok";
  public cancelLabel = "Cancel";

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogViewModel) {


    if (data.description) {
      this.question = data.description;
    }

    if (data.okLabel) {
      this.okLabel = data.okLabel;
    }

    if (data.cancelLabel) {
      this.cancelLabel = data.cancelLabel;
    }
  }


  public confirm() {
    this.dialogRef.close(true);
  }


  public cancel() {
    this.dialogRef.close(false);
  }


}

export interface ConfirmationDialogViewModel {
  description?: string;
  okLabel?: string;
  cancelLabel?: string;
}
