import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChoiceSet} from '../../entities/choice-set';

@Component({
    selector: 'app-choice-set',
    templateUrl: './choice-set.component.html',
    styleUrls: ['./choice-set.component.scss']
})
export class ChoiceSetComponent implements OnInit {

    @Input()
    content: ChoiceSet;
    currentValue: string;

    @Output() value: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit() {
    }


    send(content) {
        this.currentValue = content;
        this.value.emit(content);
    }
}
