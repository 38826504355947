import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {User} from '../../shared/entities/user';
import {Group} from '../../community/entities/group';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/forkJoin';
import {Page} from '../../cms/entities/page';

@Injectable()
export class UserService {

  private user = new Subject<User>();
  private groups = new BehaviorSubject<Array<Group>>([]);
  public uid: string;


  constructor(public firebaseAuth: AngularFireAuth, private db: AngularFirestore) {

    this.uid = '';
    this.getGroups();
  }

  login(email, password) {
    return this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(email, password);
  }

  logout() {
    this.uid = null;
    return this.firebaseAuth.auth.signOut();
  }

  register(email, password) {
    return this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  addUser(newUser) {
    this.db.collection<any>('users/').doc(newUser.id).set(newUser);
  }

  getGroups(): Observable<Group[]> {

    this.firebaseAuth.authState.subscribe(res => {
      if (res && res.uid) {
        this.uid = res.uid;

        this.db.collection('groups').ref
          .where('members', 'array-contains', this.uid)
          .get()
          .then(snapshot => {
            const groups = [];
            snapshot.docs.forEach(doc => {
              groups.push(new Group(doc.data()));
            });
            this.groups.next(groups);
          });
      } else {
        this.groups.next([]);
      }
    });
    return this.groups;
  }

  getUser() {
    return this.user;
  }

  getUserAuth() {
    return this.firebaseAuth.authState;
  }

  getUserId() {
    return this.uid;
  }


  setMessageToken(token) {
    const self = this;
    self.firebaseAuth.auth.onAuthStateChanged(function (auth) {
      if (auth) {
        self.user.take(1).subscribe(data => {
          const user = new User(data);
          user.addMessageToken(token);
          self.db.collection<any>('users/').doc(user.id).update({'messageTokens': user.messageTokens});
        });
      }
    });
  }

  getNumberOfUsers() {
    return this.db.collection<Page>('users').get().map(data => {
      return data.docs.length;
    });
  }
}
