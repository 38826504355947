import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../app/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationsService} from '../../../app/services/translations.service';
import {MessagingService} from '../../../app/services/messaging.service';
import {Observable} from 'rxjs';
import {Group} from '../../../community/entities/group';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  languages: string[];
  message: any;
  public groups: Observable<Group[]>;

  constructor(private userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private translationService: TranslationsService,
              private messagingService: MessagingService) {

    this.groups = this.userService.getGroups();
  }

  ngOnInit() {
    this.languages = this.translationService.getAvailableLanguages();

    const userId = 'user001';
    this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  }

  changeLanguage(lang) {
    this.translationService.setCurentLanguage(lang);
  }

  logout() {
    this.userService.logout().then(() => {
      this.router.navigate(['/']);
    });
  }

  mailMe() {
    window.location.href = 'mailto:info@aerypton.be?subject=Heart of Men :: Support';
  }
}
