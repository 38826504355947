import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {FormControl, FormGroup} from "@angular/forms";
import {ConfirmationDialogComponent} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {UserService} from "../../services/user.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireAuth} from "@angular/fire/auth";

@Component({
    selector: 'app-avatar-upload',
    templateUrl: './avatar-upload-dialog.component.html',
    styleUrls: ['./avatar-upload-dialog.component.scss']
})
export class AvatarUploadDialogComponent implements OnInit {

    public newMainForm: FormGroup = new FormGroup({
        attachmentsForm: new FormControl('')
    });

    constructor(public dialog: MatDialog,
                public dialogRef: MatDialogRef<AvatarUploadDialogComponent>,
                public userService: UserService,
                public db: AngularFirestore,
                public afAuth: AngularFireAuth,
                @Inject(MAT_DIALOG_DATA) public data) {

        let uid = this.userService.getUserId();
    }

    ngOnInit() {
    }


    /* Handle the dialog close event */
    public close() {
        if (this.newMainForm.pristine) {
            this.dialogRef.close();
        } else {
            this.askConfirmation();
        }
    }

    /* prompt allow close dialog */

    /* todo move this to dialog ? localize */
    private askConfirmation() {
        const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
            panelClass: "ra-dialog-small",
            data: {
                question: "Are you sure you want to close this dialog? Changes will be lost."
            }
        });

        confirmDialogRef.afterClosed().subscribe(confirmClose => {
            if (confirmClose) {
                this.dialogRef.close();
            }
        });
    }
}
