import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../app/services/user.service';
import {Group} from '../../entities/group';
import {User} from '../../../shared/entities/user';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
    selector: 'app-my-groups',
    templateUrl: './my-groups.component.html',
    styleUrls: ['./my-groups.component.scss']
})
export class MyGroupsComponent implements OnInit {

    user: Observable<User>;
    groups$: Observable<Group[]>;

    constructor(public userService: UserService) {

        this.user = this.userService.getUser();
        this.groups$ = this.userService.getGroups();
    }

    ngOnInit() {
    }
}
