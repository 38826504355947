import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app/components/app/app.component';
import {CmsModule} from './cms/cms.module';
import {SharedModule} from './shared/shared.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MatButtonModule,
    MatCardModule, MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {LoginComponent} from './app/components/login/login.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NotFoundComponent} from './app/components/not-found/not-found.component';
import {LoggedinGuard} from './app/entities/loggedin-guard';
import {TranslationsService} from './app/services/translations.service';
import {ChatboxModule} from './chatbox/chatbox.module';
import {CommunityModule} from './community/community.module';
import {WelcomeComponent} from './app/components/welcome/welcome.component';
import {MessagingService} from './app/services/messaging.service';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AlertsComponent} from './app/components/alerts/alerts.component';
import {RegisterComponent} from "./app/components/register/register.component";
import {AgmCoreModule} from '@agm/core';
import {CommonModule} from '@angular/common';
import {AlertService} from "./app/services/alert.service";
import {UserPageComponent} from './app/components/user-page/user-page.component';
import {ProfileComponent} from "./app/profile/profile.component";
import {AvatarUploadDialogComponent} from "./app/profile/avatar-upload-dialog/avatar-upload-dialog.component";


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        NotFoundComponent,
        WelcomeComponent,
        AlertsComponent,
        UserPageComponent,
        ProfileComponent,
        AvatarUploadDialogComponent
    ],
    imports: [
        CommunityModule,
        ChatboxModule,
        CmsModule,
        SharedModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        MatToolbarModule,
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        MatCardModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatMenuModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
        AngularFireModule.initializeApp(environment.firebase, 'HeartOfMen'),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireMessagingModule,
        ReactiveFormsModule,
        RouterModule.forRoot(
            [
                {
                    path: 'login',
                    component: LoginComponent
                },
                {
                    path: 'alerts',
                    component: AlertsComponent,
                    canActivate: [LoggedinGuard]
                },
                {
                    path: '',
                    component: WelcomeComponent,
                    pathMatch: 'full'
                },
                {
                    path: 'user/:id',
                    component: UserPageComponent,
                    canActivate: [LoggedinGuard]
                },
                {
                    path: 'profiel',
                    component: ProfileComponent,
                    canActivate: [LoggedinGuard]
                },
                {
                    path: '**',
                    component: NotFoundComponent
                }
            ]
        ),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
        AgmCoreModule.forRoot({apiKey: environment.maps.key})
    ],
    providers: [
        LoggedinGuard,
        TranslationsService,
        MessagingService,
        AlertService
    ],
    entryComponents: [
        AvatarUploadDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
