export class User {
    id: string;
    name: string;
    lastname: string;
    firstname: string;
    language: string;
    vat: string;
    street: string;
    number: string;
    zip: string;
    city: string;
    email: string;
    avatar: string;
    groups: Array<string>;
    messageTokens: Array<string>;

    constructor(json) {
        this.id = json.id ? json.id : '';
        this.name = json.name ? json.name : '';
        this.lastname = json.lastname ? json.lastname : '';
        this.firstname = json.firstname ? json.firstname : '';
        this.language = json.language ? json.language : '';
        this.vat = json.vat ? json.vat : '';
        this.street = json.street ? json.street : '';
        this.number = json.number ? json.number : '';
        this.zip = json.zip ? json.zip : '';
        this.city = json.city ? json.city : '';
        this.email = json.email ? json.email : '';

        this.avatar = 'https://firebasestorage.googleapis.com/v0/b/bosch-poc.appspot.com/o/users%2Fuser-2517433_960_720.png?alt='
            + 'media&token=7327ba48-8499-4cc4-87ec-7c2093ff5e48';
        if (json.attachments) {
            json.attachments.forEach(att => {
                this.avatar = att.downloadUrl;
            });
        }

        this.groups = [];
        if (json.groups) {
            json.groups.forEach(groupId => {
                this.groups.push(groupId);
            });
        }

        this.messageTokens = [];
        if (json.messageTokens) {
            json.messageTokens.forEach(token => {
                this.messageTokens.push(token);
            });
        }
    }

    addMessageToken(token) {
        if (this.messageTokens.indexOf(token) === -1) {
            this.messageTokens.push(token)
        }
    }

    removeMessageToken(token) {
        const index = this.messageTokens.indexOf(token, 0);
        if (index > -1) {
            this.messageTokens.splice(index, 1);
        }
    }
}

