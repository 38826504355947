export class ChoiceSet {

    value: any;
    id: string;
    type: string;
    choices: Array<any>;

    constructor(json) {
        this.type = 'choice-set';
        this.id = json.id;

        this.choices = [];
        json.choices.forEach(item => this.choices.push(item));
    }
}
