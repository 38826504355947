import {take} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {CmsService} from '../../services/cms.service';
import {Page} from '../../entities/page';
import {Status} from '../../entities/status.enum';
import {Attachment} from '../../../shared/entities/attachment';
import {AttachmentService} from '../../../shared/services/attachment.service';


@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {


    public pageid: string;
    public page = new Page({});
    public pagenumbers = Array.apply(null, {length: 500}).map(Number.call, Number);
    status = Status;
    froalaContent = '';
    froalaContent$ = new Subject<string>();
    attachments: Observable<Attachment[]>;


    editorOptions = {
        toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert']
    };

    constructor(private route: ActivatedRoute, private cmsService: CmsService, private attachmentService: AttachmentService) {

        this.froalaContent$.subscribe(data => {
            this.saveContent(data);
        });
    }

    ngOnInit() {

        this.route.params
            .subscribe(params => {
                this.pageid = params['id'];
            });

        this.cmsService.getPage(this.pageid).pipe(take(1)).subscribe(data => {
            this.page = new Page(data);
            this.froalaContent = this.page.content;
        });

        this.attachments = this.cmsService.getAttachments('pages/' + this.pageid);


    }


    formatSize(size) {
        return Math.floor(size / 1024) + ' kb';
    }

    keys(): Array<string> {
        const keys = Object.keys(this.status);
        return keys.slice(keys.length / 2);
    }

    saveSite(key, value) {
        this.cmsService.savePage(this.pageid, key, value);
    }

    saveTitle(value) {
        this.cmsService.savePage(this.pageid, 'title', value);
    }

    saveDescription(value) {
        this.cmsService.savePage(this.pageid, 'description', value);
    }

    saveContent(value) {
        this.cmsService.savePage(this.pageid, 'content', value);
    }

    removeAttachment(attachment: Attachment) {
        this.attachmentService.removeAttachment(attachment, 'pages/' + this.pageid);
    }


}
