import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {LocationService} from './location.service';
import {Observable, Subject} from 'rxjs';


@Injectable()
export class GroupsService {

  public selectedGroup = new Subject<string>();


  constructor(private db: AngularFirestore, private locationService: LocationService) {
  }


  public setSelectedGroup(groupid) {
    this.selectedGroup.next(groupid);
  }

  public getSelectedGroup(): Observable<string> {
    return this.selectedGroup;
  }
}
