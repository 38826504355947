import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GroupService} from '../../../services/group.service';
import {LocationService} from '../../../services/location.service';
import {LonLat} from '../../../entities/lonlat';
import {Group} from '../../../entities/group';
import {ControlPosition, StreetViewControlOptions, ZoomControlOptions} from '@agm/core/services/google-maps-types';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    public groups: Array<Group> = [];
    @Output() selectedGroupId: EventEmitter<string> = new EventEmitter<string>();
    public zoomControlOptions: ZoomControlOptions = {
        position: ControlPosition.TOP_LEFT
    };
    public streetViewOptions: StreetViewControlOptions = {
        position: ControlPosition.TOP_LEFT
    };


    ngOnInit() {
    }

    constructor(public groupService: GroupService, public locationService: LocationService) {

        this.groupService.getGroups().subscribe((groupList: Group[]) => {
            this.groups = [];

            groupList.forEach(group => {
                this.groups.push(group);
            });
            this.setMarkers('');

        });

    }

    public getLocation(): LonLat {
        return this.locationService.geolocation;
    }

    public selectGroup(groupid) {
        this.setMarkers(groupid);
        this.selectedGroupId.emit(groupid);
    }


    private setMarkers(groupid) {

        this.groups.map(group => {
                group.marker = {
                    url: group.logo,
                    scaledSize: {
                        width: 30,
                        height: 30
                    }
                };

                return group;
            }
        );
    }
}
