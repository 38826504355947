import {Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AttachmentService} from '../../services/attachment.service';
import {map} from "rxjs/operators";
import {Attachment} from "../../entities/attachment";
import {UserService} from "../../../app/services/user.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";


@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

    @Input() path: string;
    @ViewChild('uploadinput') uploadinput: ElementRef;


    public images$: Observable<any>;
    public uploadPercent = 0;


    constructor(private attachmentService: AttachmentService,
                public db: AngularFirestore,
                public userService: UserService) {

        let uid = this.userService.getUserId();
    }
    ngOnInit() {
        this.images$ = this.db.collection(this.path + '/attachments').valueChanges().pipe(map(docs => {
            return docs.filter((doc: Attachment) => doc.extension === 'png' || doc.extension === 'jpg');
        }))
    }

    public select(image) {
        image.active = !image.active;

    }

    public close() {

    }

    uploadFile(event) {
        const file = event.target.files[0];
        this.attachmentService.addAttachment(file, this.path).subscribe(percent => {
            this.uploadPercent = percent;
            console.log(percent);
        }, () => {
        }, () => {
            this.uploadPercent = 0;
        });
    }


}
