import {LonLat} from './lonlat';
import {Address} from './address';

export class Appointment {
    id: string;
    date: number;
    location: LonLat;
    address: Address;
    description: string;

    constructor(json) {
        this.id = json.id ? json.id : '';
        this.date = json.date ? json.date : '';
        this.description = json.description ? json.description : '';

        this.location = json.location ?
            new LonLat({
                lon: json.location.lon,
                lat: json.location.lat
            }) : new LonLat({lon: 0, lat: 0});

        if (json.address) {
            this.address = new Address(json.address);
            if (json.address.location) {
                this.address.setLonlat(new LonLat(json.address.location));
            }
        }
    }
}
