import {Routes} from '@angular/router';
import {HomeComponent} from '../shared/components/home/home.component';
import {SearchComponent} from './components/search/search.component';
import {LoggedinGuard} from '../app/entities/loggedin-guard';
import {MyGroupsComponent} from './components/my-groups/my-groups.component';
import {NewGroupComponent} from './components/search/new-group/new-group.component';
import {RegisterComponent} from '../app/components/register/register.component';
import {GroupItemComponent} from './components/group-item/group-item.component';
import {GroupActivityComponent} from './components/group-activity/group-activity.component';

export const communityRoutes: Routes = [
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'community',
    component: HomeComponent
  }, {
    path: 'group/:id',
    component: GroupItemComponent,
    canActivate: [LoggedinGuard]
  }, {
    path: 'group/new',
    component: NewGroupComponent
  },
  {
    path: 'groups',
    component: SearchComponent
  },
  {
    path: 'groepen',
    component: SearchComponent

  }, {
    path: 'mijn-groepen',
    component: MyGroupsComponent,
    canActivate: [LoggedinGuard]
  }, {
    path: 'my-groups',
    component: MyGroupsComponent,
    canActivate: [LoggedinGuard]
  }, {
    path: 'statistics',
    component: GroupActivityComponent,
    canActivate: [LoggedinGuard]
  }
];
