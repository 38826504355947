import {Component, Input, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {User} from '../../entities/user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {


  private _userid: string;
  public user: User = new User({});

  public showIcon = true;
  public showName = true;

  @Input()
  public mailbody = '';


  @Input()
  get userid() {
    return this._userid;
  }

  set userid(id) {
    this._userid = id;
    this.db.doc('users/' + id).valueChanges().take(1).subscribe(data => {
      if (data) {
        this.user = new User(data);
      } else {
        this.user = new User({id: this._userid, name: 'deleted user'});
      }
    });
  }

  @Input()
  set format(size) {
    switch (size) {
      case 'icon':
        this.showIcon = true;
        this.showName = false;
        break;
      case 'name':
        this.showIcon = false;
        this.showName = true;
        break;
      default:
        this.showIcon = true;
        this.showName = true;
    }
  }

  constructor(private db: AngularFirestore) {
  }

  ngOnInit() {
  }

  sendMail() {
    window.open('mailto:' + this.user.email + '?subject=Heart of Men&body=Hoi ' + this.user.firstname + this.mailbody);

  }
}
