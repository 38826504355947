export class Alert {

    userid: string;
    body: string;
    title: string;
    action: string;
    datetime: number;

    constructor(json) {
        this.body = json.body ? json.body : 'Klik hier om meer informatie...';
        this.title = json.title ? json.title : 'Nieuwe melding';
        this.action = json.action ? json.action : '/groepen';
        this.userid = json.userid ? json.userid : '123456789';
        this.datetime = json.datetime ? json.datetime : new Date().getTime();
    }
}
