import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';

import {Attachment} from '../entities/attachment';
import 'rxjs-compat/add/operator/map';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/internal/operators';

@Injectable()
export class AttachmentService {

    uploadPercent: Observable<number>;
    downloadURL: Observable<string>;

    constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
    }


    addAttachment(file, path) {

        const now = new Date().getTime();
        const filePath = path + '/attachments/' + now + '_' + file.name;

        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);


        task.snapshotChanges()
            .pipe(
                finalize(() => {
                    /*
                    */

                    fileRef.getDownloadURL().subscribe(data => {
                        console.log(data);
                        const newData = {
                            'name': file.name,
                            'downloadUrl': data,
                            'size': file.size,
                            'creationDate': now
                        };


                        const newDocRef = this.db.collection<any>(path + '/attachments')
                            .add(this.parseToObject(new Attachment(newData)))
                            .then((newRef) => {
                                newRef.update({id: newRef.id});
                            });
                    });


                })
            )
            .subscribe();

        return task
            .percentageChanges()
            .pipe(
                map(x => Math.floor(x))
            );
    }

    removeAttachment(attachment: Attachment, path) {

        this.db
            .doc(path + '/attachments/' + attachment.id).delete().then(() => {
            this.storage
                .ref(path + '/attachments/' + attachment.creationDate + '_' + attachment.name)
                .delete();
        });
    }

    getAttachments(path) {

        return this.db.collection(path + '/attachments').valueChanges().pipe(map(data => {

            data.forEach((att, index) => {
                data[index] = new Attachment(att);
            });

            data.sort((a: Attachment, b: Attachment) => {
                return (a.creationDate > b.creationDate) ? -1 : ((b.creationDate > a.creationDate) ? +1 : 0);
            });

            return data;
        }));
    }


    private parseToObject(json): any {


        if (json instanceof Array) {
            json.forEach((item, index) => {
                json[index] = this.parseToObject(item);
            });

        } else if (typeof json === 'object') {
            json = Object.assign({}, json);
            for (let key in json) {
                json[key] = this.parseToObject(json[key]);
            }
        }
        return json;
    }
}
