import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

import {Page} from '../entities/page';
import {Attachment} from '../../shared/entities/attachment';
import {NotificationService} from '../../shared/services/notification.service';


@Injectable()
export class CmsService {

    private pageCollection: AngularFirestoreCollection<Page>;
    pages: Observable<Page[]>;


    constructor(private db: AngularFirestore, private notificationService: NotificationService) {
        db.firestore.settings({timestampsInSnapshots: true});
        this.pageCollection = db.collection<Page>('pages');
        this.pages = this.pageCollection.valueChanges();
    }


    public getPages() {
        return this.db.collection<Page>('pages').valueChanges();
    }


    public getPage(pageid: string): Observable<Page> {
        return this.db.doc<Page>('pages/' + pageid).valueChanges();
    }

    public getAttachments(path): Observable<Attachment[]> {

        return this.db.collection(path + '/attachments').valueChanges().pipe(map(data => {

            const attachments = [];

            data.forEach((att, index) => {
                attachments[index] = new Attachment(att);
            });

            attachments.sort((a: Attachment, b: Attachment) => {
                return (a.creationDate > b.creationDate) ? -1 : ((b.creationDate > a.creationDate) ? +1 : 0);
            });

            return attachments;
        }));
    }


    public createpage() {
        const id = this.db.createId();
        const item = new Page({id: id, status: 'draft'});
        this.pageCollection.doc(id).set(this.parseToObject(item));
    }


    public savePage(pageid: string, key: string, value: string | number) {
        if (value || value === 0) {
            let newPage: Page;
            this.getPage(pageid).pipe(take(1)).subscribe(data => {
                newPage = new Page(data);
                newPage[key] = value;
                this.setPage(pageid, newPage);
            });
        } else {
            this.notificationService.showError('Value for ' + key + ' not saved: ' + value);
        }
    }

    /*
     *  Private Methods
     */

    private setPage(pageid: string, newPage: Page) {
        newPage.id = pageid;
        this.db.doc<Page>('pages/' + pageid).set(this.parseToObject(newPage));
    }


    private parseToObject(json): any {

        if (json instanceof Array) {
            json.forEach((item, index) => {
                json[index] = this.parseToObject(item);
            });

        } else if (typeof json === 'object') {
            json = Object.assign({}, json);
            for (let key in json) {
                json[key] = this.parseToObject(json[key]);
            }
        }
        return json;

    }
}
