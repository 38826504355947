import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

import {CanvasComponent} from './components/canvas/canvas.component';
import {UploadComponent} from './components/upload/upload.component';
import {NotificationComponent} from './components/notification/notification.component';

import {AttachmentService} from './services/attachment.service';
import {RouterModule} from '@angular/router';
import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule, MatInputModule, MatMenuModule,
    MatOptionModule, MatRadioModule, MatSelectModule, MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {NotificationService} from './services/notification.service';
import {SliderComponent} from './components/slider/slider.component';
import {ChoiceSetComponent} from './components/choice-set/choice-set.component';
import {UserComponent} from './components/user/user.component';
import {HomeComponent} from './components/home/home.component';
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [
        CanvasComponent,
        UploadComponent,
        NotificationComponent,
        SliderComponent,
        ChoiceSetComponent,
        UserComponent,
        HomeComponent,
        ConfirmationDialogComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        ReactiveFormsModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatTabsModule,
        MatCardModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatMenuModule,
        MatSnackBarModule,
        TranslateModule
    ],
    providers: [
        AttachmentService,
        NotificationService
    ],
    exports: [
        CanvasComponent,
        UploadComponent,
        NotificationComponent,
        SliderComponent,
        ChoiceSetComponent,
        UserComponent,
        HomeComponent
    ],
    entryComponents: [
        NotificationComponent,
        ConfirmationDialogComponent
    ],
})
export class SharedModule {

}
