import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslationsService} from '../../services/translations.service';
import {TranslateService} from '@ngx-translate/core';
import {GroupService} from '../../../community/services/group.service';
import {UserService} from '../../services/user.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./ie.css']
})
export class WelcomeComponent implements OnInit {

  languages: any;
  groupCounter$: Observable<number>;
  userCounter$: Observable<number>;

  constructor(private router: Router,
              private translate: TranslateService,
              private translationService: TranslationsService,
              public groupService: GroupService,
              private userService: UserService) {

  }

  ngOnInit() {
    this.languages = this.translationService.getAvailableLanguages();
    this.translationService.setCurentLanguage('nl');
    this.groupCounter$ = this.groupService.getNumberOfGroups();
    this.userCounter$ = this.userService.getNumberOfUsers();
  }


  changeLanguage(lang) {
    this.translationService.setCurentLanguage(lang);
    this.translate.use(this.translationService.getCurrentLanguage());
  }

}
