import {LonLat} from './lonlat';
import {Address} from './address';


export class Group {

  public id: string;
  public name: string;
  public description: string;
  public location: LonLat;
  public marker?: string | object;
  public logo: string;
  public address?: Address;
  public members: Array<string>;
  public organisers: Array<string>;
  public appointments: Array<string>;
  public candidates: Array<string>;
  public price: number;
  public language: string;

  constructor(json: any = {}) {

    this.id = json.id ? json.id : 0;
    this.name = json.name ? json.name : '';
    this.price = json.price ? json.price : 0;
    this.description = json.description ? json.description : '';
    this.logo = json.logo ? json.logo : '/assets/icons/marker.svg';
    this.location = json.location ?
      new LonLat({
        lon: json.location.lon,
        lat: json.location.lat
      }) : new LonLat({lon: 0, lat: 0});

    if (json.address) {
      this.address = new Address(json.address);
      if (json.address.location) {
        this.address.setLonlat(new LonLat(json.address.location));
      }
    }
    this.members = [];
    if (json.members) {
      json.members.forEach(member => this.members.push(member));
    }
    this.organisers = [];
    if (json.organisers) {
      json.organisers.forEach(organiser => this.organisers.push(organiser));
    }
    this.candidates = [];
    if (json.candidates) {
      json.candidates.forEach(organiser => this.candidates.push(organiser));
    }
    this.appointments = [];
    if (json.appointments) {
      json.appointments.forEach(appointment => this.appointments.push(appointment));
    }

    if (json.address) {
      this.address = new Address(json.address);
    } else {
      this.address = new Address({});
    }
  }
}
