import {Component, Input, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Appointment} from '../../entities/appointment';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {


    private _appointmentid: string;
    public appointment: Appointment = new Appointment({});

    @Input()
    get appointmentid() {
        return this._appointmentid;
    }

    set appointmentid(id) {
        this._appointmentid = id;
        this.db.doc('appointments/' + id).valueChanges().take(1).subscribe(data => this.appointment = new Appointment(data));
    }

    constructor(private db: AngularFirestore) {
    }

    ngOnInit() {
    }

}
