import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import 'rxjs-compat/add/operator/take';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    email: string;
    password: string;
    error: string;
    errorFromBackend: string;

    user: Observable<any>;

    constructor(private userService: UserService, private router: Router) {


        this.userService.getUserAuth().subscribe(data => {
            if (data) {
                this.router.navigate(['/mijn-groepen']);
            }
        })
    }

    ngOnInit() {
        this.email = '';
        this.password = '';
    }

    login() {
        this.userService.login(this.email, this.password).then(() => {
            this.router.navigate(['/mijn-groepen']);
        }, error => {
            this.errorFromBackend = error.message;
        }).catch(error => {
            this.errorFromBackend = error.message;
        });
    }


}
