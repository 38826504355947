import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

    constructor() {
    }
}

