import {Injectable} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {GroupService} from "../../community/services/group.service";
import {Alert} from "../entities/alert";

@Injectable()
export class AlertService {

    constructor(private db: AngularFirestore) {

    }

    createAlert(userid, title, body, action, fromid = '') {
        let alert = new Alert({title: title, body: body, action: action, userid: fromid});
        this.db.collection<any>('users/' + userid + '/alerts/').add(this.parseToObject(alert));
    }

    getAlerts(userid) {
        return this.db
            .collection(
                '/users/' + userid + '/alerts',
                ref =>
                    ref.orderBy('datetime', 'desc').limit(100)
            )
            .valueChanges().map(data => {
                let alerts = [];

                data.forEach(alert => {
                    alerts.push(new Alert(alert));
                });
                return alerts;
            });
    }


    private parseToObject(json): any {


        if (json instanceof Array) {
            json.forEach((item, index) => {
                json[index] = this.parseToObject(item);
            });

        } else if (typeof json === 'object') {
            json = Object.assign({}, json);
            for (let key in json) {
                json[key] = this.parseToObject(json[key]);
            }
        }
        return json;
    }
}
