import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import 'rxjs-compat/add/operator/take';
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore} from "@angular/fire/firestore";
import {User} from "../../../shared/entities/user";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    email: string;
    password: string;
    newEmail: string;
    newPassword: string;
    newPassword2: string;
    newFirstname: string;
    newLastname: string;
    error: string;
    errorFromBackend: string;

    user: Observable<User>;

    constructor(private userService: UserService, private router: Router, public firebaseAuth: AngularFireAuth, private db: AngularFirestore) {
        this.user = this.userService.getUser();
    }

    ngOnInit() {
        this.email = '';
        this.password = '';
    }

    login() {
        this.userService.login(this.email, this.password).then(() => {
            this.router.navigate(['/']);
        });
    }

    register() {
        this.error = '';
        this.errorFromBackend = '';

        if (!this.newFirstname) {
            this.error = 'register.error.no_firstname';
        } else if (!this.newLastname) {
            this.error = 'register.error.no_lastname';
        } else if (!this.newEmail) {
            this.error = 'register.error.no_email';
        } else if (!this.newFirstname) {
            this.error = 'register.error.no_firstname';
        } else if (!this.newPassword) {
            this.error = 'register.error.no_password';
        } else if (this.newPassword.length < 6) {
            console.log(this.newPassword.length);
            this.error = 'register.error.weak_password';
        } else if (this.newPassword !== this.newPassword2) {
            this.error = 'register.error.passwords_do_not_match';
        } else {
            this.firebaseAuth.auth.createUserWithEmailAndPassword(this.newEmail, this.newPassword).then(data => {
                const newUser = {
                    id: data.user.uid,
                    firstname: this.newFirstname,
                    lastname: this.newLastname,
                    email: this.newEmail
                };
                this.userService.addUser(newUser);
                this.user = this.userService.getUser();
                this.router.navigate(['/mijn-groepen']);
            }, error => {
                this.errorFromBackend = error.message;
            }).catch(error => {
                this.errorFromBackend = error.message;
            });

        }

    }
}
