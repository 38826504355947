import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Group} from '../../../entities/group';
import {GroupService} from '../../../services/group.service';
import {UserService} from '../../../../app/services/user.service';

@Component({
  selector: 'app-search-group-item',
  templateUrl: './search-group-item.component.html',
  styleUrls: ['./search-group-item.component.scss']
})
export class SearchGroupItemComponent {

  private _groupid: string;
  public group = new Group({});


  @Output()
  groupidChange = new EventEmitter<string>();

  @Input()
  get groupid() {
    return this._groupid;
  }

  set groupid(groupid: string) {
    this._groupid = groupid;
    if (this._groupid.length) {
      this.groupService.getGroup(this._groupid).take(1).subscribe(data => this.group = data);
    } else {
      this.group = new Group({});
    }
    this.groupidChange.emit(this._groupid);
  }


  constructor(public groupService: GroupService, private userService: UserService) {
  }

  close() {
    this.groupidChange.emit('');
  }

  getUserId() {
    return this.userService.getUserId();
  }

  isMember() {
    const uid = this.userService.getUserId();
    if (uid) {
      return (this.group.members.indexOf(uid) > -1);
    } else {
      return false;
    }
  }

  isCandidate() {
    const uid = this.userService.getUserId();
    if (uid) {
      return (this.group.candidates.indexOf(uid) > -1);
    } else {
      return false;
    }
  }


  isOwner() {
    const uid = this.userService.getUserId();
    if (uid) {
      return (this.group.organisers.indexOf(uid) > -1);
    } else {
      return false;
    }
  }

  askMembership() {
    const uid = this.userService.getUserId();
    this.group.candidates.push(uid);
    this.groupService.askMembership(this.groupid, uid);
  }

  removeCandidacy() {
    const uid = this.userService.getUserId();

    for (let i = this.group.candidates.length; i--;) {
      if (this.group.candidates[i] === uid) {
        this.group.candidates.splice(i, 1);
      }
    }

    this.groupService.removeMembership(this.groupid, uid);
  }

}
