import {Component, OnDestroy} from '@angular/core';
import {SocketService} from '../../services/socket.service';
import {Subscription} from 'rxjs';
import {MessageItem} from '../../entities/message';


@Component({
    selector: 'app-chatbox',
    templateUrl: './chatbox.component.html',
    styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent implements OnDestroy {

    public messages = [];
    public content: string;
    public newMessage: any;
    private subscription: Subscription;
    public user: any;
    public visible = true;

    constructor(private socket: SocketService) {

        this.user = this.socket.getUser();

        this.subscription = socket.getMessages().subscribe(message => {
            this.messages.push(message);
            this.newMessage = message.attachments;
        });

        this.content = '';
    }


    ngOnDestroy() {
        this.subscription.unsubscribe();
    }


    sendMessage(value) {
        const message = new MessageItem({
            from: this.user,
            text: value.text
        });
        message.addValue(value);


        this.socket.sendMessage(message).take(1)
            .subscribe(
                data => {
                    this.content = '';
                },
                err => {
                    console.log(err);
                }
            );
    }


    close() {
        this.visible = false;
    }

    open() {
        this.visible = true;
    }
}
