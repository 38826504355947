import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TranslationsService {

    private currentLanguage = 'nl';
    private languages = ['en', 'nl', 'he'];

    constructor(private translate: TranslateService) {

    }


    setCurentLanguage(lang) {
        if (this.languages.indexOf(lang) > -1) {
            this.currentLanguage = lang;
        } else {
            this.currentLanguage = 'en';
        }
        this.translate.use(this.currentLanguage);
    }

    getCurrentLanguage() {
        return this.currentLanguage;
    }

    getAvailableLanguages() {
        return this.languages;
    }

}
