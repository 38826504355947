import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapComponent} from './components/search/map/map.component';
import {MyLocationComponent} from './components/search/my-location/my-location.component';
import {SearchComponent} from './components/search/search.component';
import {GroupsService} from './services/groups.service';
import {GroupService} from './services/group.service';
import {LocationService} from './services/location.service';
import {UserService} from '../app/services/user.service';
import {RouterModule} from '@angular/router';
import {communityRoutes} from './routes';
import {AgmCoreModule} from '@agm/core';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatOptionModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import {ChatboxModule} from '../chatbox/chatbox.module';
import {AppointmentComponent} from './components/appointment/appointment.component';
import {MyGroupsComponent} from './components/my-groups/my-groups.component';
import {ChatComponent} from './components/my-groups/chat/chat.component';
import {NewGroupComponent} from './components/search/new-group/new-group.component';
import {environment} from '../../environments/environment';
import {AlertService} from '../app/services/alert.service';
import {SearchGroupItemComponent} from './components/search/search-group-item/search-group-item.component';
import {GroupItemComponent} from './components/group-item/group-item.component';
import { GroupActivityComponent } from './components/group-activity/group-activity.component';

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    ChatboxModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatMenuModule,
    MatSnackBarModule,
    TranslateModule,
    CommonModule,
    RouterModule.forChild(communityRoutes),
    AgmCoreModule.forRoot({apiKey: environment.maps.key})
  ],
  declarations: [
    MapComponent,
    MyLocationComponent,
    SearchComponent,
    GroupItemComponent,
    AppointmentComponent,
    MyGroupsComponent,
    ChatComponent,
    NewGroupComponent,
    SearchGroupItemComponent,
    GroupActivityComponent
  ],
  providers: [
    GroupsService,
    GroupService,
    LocationService,
    UserService,
    AlertService
  ],
  exports: []
})
export class CommunityModule {
}
