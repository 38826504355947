import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChatboxComponent} from './components/chatbox/chatbox.component';
import {SocketService} from './services/socket.service';
import {RouterModule} from '@angular/router';
import {routes} from './routes';
import {MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule, MatSelectModule} from '@angular/material';
import {MessagelistComponent} from './components/messagelist/messagelist.component';
import {SharedModule} from '../shared/shared.module';
import {AdaptiveCardComponent} from './components/adaptive-card/adaptive-card.component';
import { HomeComponent } from './components/home/home.component';
import { FormComponent } from './components/form/form.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        SharedModule
    ],
    providers: [
        SocketService
    ],
    declarations: [
        ChatboxComponent,
        MessagelistComponent,
        AdaptiveCardComponent,
        HomeComponent,
        FormComponent
    ],
    exports: [
        ChatboxComponent
    ]

})
export class ChatboxModule {
}
