import {Component, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {Observable} from 'rxjs/Observable';
import {Page} from '../../entities/page';
import {Status} from '../../entities/status.enum';

@Component({
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {


    pages: Observable<Page[]>;
    status = Status;

    constructor(private cmsService: CmsService) {
        this.pages = this.cmsService.getPages();
    }

    ngOnInit() {
    }


    keys(): Array<string> {
        const keys = Object.keys(this.status);
        return keys.slice(keys.length / 2);
    }


    createNew() {
        this.cmsService.createpage();
    }
}
