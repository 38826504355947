import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {AlertService} from "../../services/alert.service";

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

    userid: string;

    public alerts: Observable<any>;

    constructor(public userService: UserService, private alertService: AlertService) {

    }

    ngOnInit() {

        this.userService.getUserAuth().subscribe(data => {
            this.alerts = this.alertService.getAlerts(data.uid);
        });
    }

}
