import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'baloise-messagelist',
  templateUrl: './messagelist.component.html',
  styleUrls: ['./messagelist.component.scss']
})
export class MessagelistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
