import {Status} from './status.enum';

export class Page {
    id: string;
    description: string;
    title: string;
    content: string;
    attachments: any[];
    status: Status;

    tonvanderkroon: number;
    heartofmen: number;
    treeoflife: number;
    channelings: number;


    constructor(json: any) {
        this.id = json.id ? json.id : '';
        this.description = json.description ? json.description : '';
        this.content = json.content ? json.content : '';
        this.title = json.title ? json.title : 'No title yet...';
        this.attachments = json.attachments ? json.attachments : [];
        this.status = json.status ? json.status : 0;

        this.tonvanderkroon = json.tonvanderkroon ? json.tonvanderkroon : 0;
        this.heartofmen = json.heartofmen ? json.heartofmen : 0;
        this.treeoflife = json.treeoflife ? json.treeoflife : 0;
        this.channelings = json.channelings ? json.channelings : 0;
    }
}
