export const Formfields = [
    {
        title: 'Age',
        questions: [{
            key: 'age',
            label: 'Wat is je leeftijd?',
            type: 'number',
            value: '',
            min: 1,
            max: 120
        }]
    },
    {
        title: 'familySituation',
        questions: [
            {
                key: 'familySituationType',
                label: 'Wat is je familie situatie?',
                value: '',
                options: ['Single', 'LivingTogether', 'LegallyCohabiting', 'Married', 'Widow']
            },
            {
                key: 'numberOfDependants',
                label: 'Hoeveel mensen heb je ten laste?',
                value: '',
                type: 'number'
            }
        ]
    },
    {
        title: 'Statute',
        questions: [
            {
                key: 'statute',
                label: 'Wat is je professioneel statuut?',
                value: '',
                options: ['Student', 'Employee', 'Independent', 'CompanyDirector', 'ConcractualOfficial', 'StatutoryOfficial']
            }
        ]
    },
    {
        title: 'salary',
        questions: [
            {
                key: 'salaryAmount',
                label: 'Wat is je salaris?',
                value: '',
                type: 'number'
            },
            {
                key: 'isNet',
                label: 'Is dat netto?',
                value: '',
                options: ['ja', 'neen']
            }
        ]
    },
    {
        title: 'PartnerSalary',
        questions: [
            {
                key: 'salaryAmount',
                label: 'Wat is het salaris van je partner?',
                value: '',
                type: 'number'
            },
            {
                key: 'isNet',
                label: 'Is dat netto?',
                value: '',
                options: ['ja', 'neen']
            }
        ]
    }
];


