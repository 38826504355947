import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Slider} from '../../entities/slider';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

    @Input()
    content: Slider;

    @Output() value: EventEmitter<number> = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit() {
    }

    update(content) {
        this.content.value = content;
        this.value.emit(this.content.value);
    }
}
