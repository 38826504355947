import {Component} from '@angular/core';
import {Group} from '../../entities/group';
import {GroupService} from '../../services/group.service';
import {UserService} from '../../../app/services/user.service';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-group-item',
  templateUrl: './group-item.component.html',
  styleUrls: ['./group-item.component.scss']
})
export class GroupItemComponent {

  private groupid: string;
  public group$ = new BehaviorSubject(new Group());

  public isMember: boolean;
  public isOwner: boolean;
  public isCandidate: boolean;
  public me: string;


  constructor(public groupService: GroupService, private userService: UserService, public route: ActivatedRoute) {


    this.route.paramMap.subscribe(params => {

      this.groupid = params.get('id');
      this.me = this.userService.getUserId();

      this.groupService.getGroup(this.groupid).subscribe(group => {
        this.isMember = this.groupService.isMember(group, this.userService.getUserId());
        this.isOwner = this.groupService.isOwner(group, this.userService.getUserId());
        this.isCandidate = this.groupService.isCandidate(group, this.userService.getUserId());
        this.group$.next(group);
      });


    });

  }


  public accept(userid: string) {
    if (this.isOwner) {
      this.groupService.acceptMembership(this.groupid, userid);
    }
  }

  public removeUserFormGroup(userid) {
    if (this.isOwner) {
      this.groupService.removeMemberFromGroup(this.groupid, userid);
    }
  }

  public askMembership() {
    if (this.me) {
      this.groupService.askMembership(this.groupid, this.me);
    }
  }

  public removeCandidacy() {
    if (this.me) {
      this.groupService.removeMembership(this.groupid, this.me);
    }
  }

  public invite() {
    window.open('mailto:?subject=Heart of Men :: Invitation&body=https://heartofmen.net/group/' + this.groupid);
  }

  public updateGroupName(name: string) {
    this.groupService.updateGroupName(this.groupid, name, this.me);
  }

  public updateGroupDescription(description: string) {
    this.groupService.updateGroupDescription(this.groupid, description, this.me);
  }
}
