import {LoggedinGuard} from '../app/entities/loggedin-guard';
import {Routes} from '@angular/router';
import {CmsComponent} from './components/cms/cms.component';
import {PageComponent} from './components/page/page.component';

export const cmsRoutes: Routes = [
  {
    path: 'cms/pages/:id',
    component: PageComponent,
    canActivate: [LoggedinGuard]
  },
  {
    path: 'cms/pages',
    component: CmsComponent,
    canActivate: [LoggedinGuard]
  }
];
