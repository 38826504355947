import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../../app/services/user.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

    @Input() chatid: string;

    public items: Observable<any>;
    public user: any;

    constructor(public userService: UserService, private db: AngularFirestore, translate: TranslateService) {

    }

    ngOnInit() {

        this.user = this.userService.getUserId();

        this.items = this.db
            .collection(
                '/groups/' + this.chatid + '/messages',
                ref =>
                    ref.orderBy('datetime', 'desc').limit(100)
            )
            .valueChanges().map(data => {
                return data;
            });
    }


    addItem(textarea) {
        if (textarea.value.length) {

            const newItem = {
                userid: this.userService.getUserId(),
                text: textarea.value,
                datetime: new Date().getTime()
            };

            this.db.collection<any>('/groups/' + this.chatid + '/messages').add(newItem);
        }
        textarea.value = '';
    }
}
