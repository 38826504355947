import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort} from '@angular/material';
import {PagesDataSource} from './pages-datasource';
import {Page} from '../../entities/page';
import {Observable, SubscriptionLike as ISubscription} from 'rxjs';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Input() pages: Observable<Page[]>;

    private subscription: ISubscription;
    dataSource: PagesDataSource;
    displayedColumns = ['title', 'description', 'tonvanderkroon', 'treeoflife', 'channelings', 'heartofmen'];


    constructor() {
    }

    ngOnInit() {
        this.subscription = this.pages.subscribe(data => {
            console.log(data);
            this.dataSource = new PagesDataSource(this.paginator, this.sort, data);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
