import {Component, OnInit} from '@angular/core';
import {SocketService} from '../../services/socket.service';
import {MessageItem} from '../../entities/message';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

    public formFields;

    constructor(private socket: SocketService) {
        this.formFields = this.socket.getFormfields();
    }

    ngOnInit() {
    }

    sendEvent(blocktitle, key, value) {

        const event = new MessageItem({text: value});
        event.value = {
            text: value,
            propertyName: blocktitle,
            flowType: 'SaferLife.Chatbot.Assets.Flows.IncomeProtectionFlow'
        };
        event.value[key] = value;


        this.socket.sendMessage(event).take(1)
            .subscribe(
                data => {
                },
                err => {
                    console.log(err);
                }
            );
    }
}
