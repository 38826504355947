export class LonLat {
    public lon: number;
    public lat: number;

    constructor(json: any = {}) {
        this.lon = Number(json.lon);
        this.lat = Number(json.lat);
    }


}