export class Slider {
    type: string;
    id: string;
    min: number;
    max: number;
    step: number;
    value: number;

    constructor(json) {
        this.type = 'slider';
        this.id = json.id;
        this.min = json.min;
        this.max = json.max;
        this.step = json.step ? json.step : 1;
        this.value = Number(json.value);
    }
}
