import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-user-page',
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent {

    public userid: String;

    constructor(public route: ActivatedRoute) {

        this.route.paramMap.subscribe(params => {

            this.userid = params.get('id');

        });
    }
}
