import {Slider} from '../../shared/entities/slider';
import {ChoiceSet} from '../../shared/entities/choice-set';

export class TextBlock {
    type: string;
    size: string;
    weight: string;
    text: string;

    constructor(json) {
        this.type = 'textblock';
        this.size = json.size;
        this.weight = json.weight;
        this.text = json.text;
    }
}

export class AdaptiveCard {

    blocks: Array<TextBlock | Slider | ChoiceSet>;
    actions: any;
    type: string;

    constructor(body, actions) {
        this.type = 'AdaptiveCard';

        this.blocks = [];
        body.forEach(data => {
            if (data.type === 'Container') {
                data.items.forEach(item => this.addBlock(item));
            } else {
                this.addBlock(data);
            }
        });

        this.actions = [];
        actions.forEach(action => {
            if (action.type === 'Action.Submit') {
                this.actions.push(action);
            }
        });
    }

    addBlock(data) {

        if (data.type === 'TextBlock') {
            this.blocks.push(new TextBlock(data));
        } else if (data.type === 'Input.Number') {
            this.blocks.push(new Slider(data));
        } else if (data.type === 'Input.ChoiceSet') {
            this.blocks.push(new ChoiceSet(data));
        }
    }
}


const test = {
    'activities': [
        {
            'type': 'message',
            'id': '3dgpoy3M5rkGEe9QxTxkjm|0000008',
            'timestamp': '2018-06-19T13:11:34.0412997Z',
            'localTimestamp': '2018-06-19T13:11:33.9910961+00:00',
            'channelId': 'webchat',
            'from': {
                'id': 'SaferLifePOC',
                'name': 'SaferLifePOC'
            },
            'conversation': {
                'id': '3dgpoy3M5rkGEe9QxTxkjm'
            },
            'text': '',
            'attachments': [
                {
                    'contentType': 'application/vnd.microsoft.card.adaptive',
                    'content': {
                        'type': 'AdaptiveCard',
                        'version': '0.5',
                        'body': [
                            {
                                'type': 'Container',
                                'items': [
                                    {
                                        'type': 'TextBlock',
                                        'size': 'large',
                                        'weight': 'bolder',
                                        'text': 'Please enter your FamilySituationType'
                                    },
                                    {
                                        'type': 'Input.ChoiceSet',
                                        'id': 'familySituationType',
                                        'style': 'expanded',
                                        'isMultiSelect': false,
                                        'choices': [
                                            {
                                                'title': 'Single',
                                                'value': 'Single'
                                            },
                                            {
                                                'title': 'LivingTogether',
                                                'value': 'LivingTogether'
                                            },
                                            {
                                                'title': 'LegallyCohabiting',
                                                'value': 'LegallyCohabiting'
                                            },
                                            {
                                                'title': 'Married',
                                                'value': 'Married'
                                            },
                                            {
                                                'title': 'Widow',
                                                'value': 'Widow'
                                            }
                                        ]
                                    },
                                    {
                                        'type': 'TextBlock',
                                        'size': 'large',
                                        'weight': 'bolder',
                                        'text': 'Please enter your NumberOfDependants'
                                    },
                                    {
                                        'type': 'Input.Number',
                                        'id': 'numberOfDependants',
                                        'value': 'NaN',
                                        'min': 0.0,
                                        'max': 10.0
                                    }
                                ]
                            }
                        ],
                        'actions': [
                            {
                                'type': 'Action.Submit',
                                'data': {
                                    'flowType': 'SaferLife.Chatbot.Flows.IncomeProtectionFlow',
                                    'propertyName': 'FamilySituation'
                                },
                                'title': 'Submit'
                            }
                        ]
                    }
                }
            ],
            'entities': [],
            'replyToId': '3dgpoy3M5rkGEe9QxTxkjm|0000007'
        }
    ],
    'watermark': '8'
};

