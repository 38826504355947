import {Component} from '@angular/core';
import {UserService} from '../../../app/services/user.service';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent {

    public selectedGroupId = '';
    public newgroup: boolean;


    constructor(private userService: UserService) {
        this.newgroup = false;
    }

    public updateSelectedGroup(id) {
        this.selectedGroupId = id;
    }

    public loggedin() {
        return this.userService.getUserId();
    }

    public toggleNewgroup() {
        this.newgroup = !this.newgroup;
    }
}
