import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TextBlock} from '../../entities/adaptive-card';
import {ChoiceSet} from '../../../shared/entities/choice-set';
import {Slider} from '../../../shared/entities/slider';

@Component({
    selector: 'app-adaptive-card',
    templateUrl: './adaptive-card.component.html',
    styleUrls: ['./adaptive-card.component.scss']
})
export class AdaptiveCardComponent implements OnInit {

    values: any;

    @Input() blocks: Array<TextBlock | Slider | ChoiceSet>;
    @Input() actions: Array<any>;
    @Output() submit: EventEmitter<any> = new EventEmitter<any>();


    constructor() {
        this.values = {};

    }

    ngOnInit() {
        if (this.actions) {
            this.actions.forEach(action => {
                if (action.type === 'Action.Submit') {
                    this.values = action.data;
                }
            });
        }
    }

    update(key, value) {
        this.values[key] = value;
        this.values.text = value;
    }

    submitContent() {
        this.submit.emit(this.values);
    }
}
