import {Component} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../services/user.service';
import {Router} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {Attachment} from "../../shared/entities/attachment";
import {AvatarUploadDialogComponent} from "./avatar-upload-dialog/avatar-upload-dialog.component";
import {MatDialog} from "@angular/material";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {



    constructor(public userService: UserService,
                public afAuth: AngularFireAuth,
                public dialog: MatDialog,
                private router: Router) {

    }

    logout() {
        this.userService.logout().then(() => {
            this.router.navigate(['/login']);
        });

    }


    editAvatar(): void {

        const dialogRef = this.dialog.open(AvatarUploadDialogComponent, {
            disableClose: true,
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('editImage: The dialog was closed');
        });
    }
}
