import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CmsComponent} from './components/cms/cms.component';
import {RouterModule} from '@angular/router';
import {cmsRoutes} from './routes';
import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule
} from '@angular/material';
import {CmsService} from './services/cms.service';
import {PageComponent} from './components/page/page.component';
import {TranslateModule} from '@ngx-translate/core';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {SharedModule} from '../shared/shared.module';
import {PagesComponent} from './components/pages/pages.component';

@NgModule({
    imports: [
        RouterModule.forChild(cmsRoutes),
        CommonModule,
        SharedModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        TranslateModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        MatTableModule,
        MatPaginatorModule,
        MatSortModule
    ],
    declarations: [
        CmsComponent,
        PageComponent,
        PagesComponent
    ],
    providers: [
        CmsService
    ],
    exports: [
        CmsComponent
    ]
})
export class CmsModule {
}
