import {fromEvent as observableFromEvent} from 'rxjs';
import {pairwise, switchMap, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';


@Component({
    selector: 'app-canvas',
    templateUrl: './canvas.component.html',
    styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements AfterViewInit {

    public canvasEl: HTMLCanvasElement;
    public image: string;
    private cx: CanvasRenderingContext2D;
    private drawing = false;

    @ViewChild('canvas') public canvas: ElementRef;

    @Input() public width = 100;
    @Input() public height = 200;
    @Input() public name = new Date().getTime();
    @Output() fileData: EventEmitter<string> = new EventEmitter<string>();


    @HostListener('touchmove', ['$event']) onTap(e) {
        e.preventDefault();
    }


    constructor() {

    }


    public ngAfterViewInit() {
        this.canvasEl = this.canvas.nativeElement;
        this.cx = this.canvasEl.getContext('2d');

        this.canvasEl.width = this.width;
        this.canvasEl.height = this.height;

        this.cx.lineWidth = 3;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#000';
        this.cx.fillStyle = '#ffffff';
        this.cx.fillRect(0, 0, this.width, this.height);

        this.captureEvents(this.canvasEl);
        this.fileData.emit('');

    }

    private captureEvents(canvasEl: HTMLCanvasElement) {


        observableFromEvent(canvasEl, 'touchstart')
            .pipe(
                switchMap((e) => {
                    return observableFromEvent(canvasEl, 'touchmove')
                        .pipe(
                            takeUntil(observableFromEvent(canvasEl, 'touchend')),
                            pairwise()
                        );
                }))
            .subscribe((res: [TouchEvent, TouchEvent]) => {

                const rect = canvasEl.getBoundingClientRect();

                const prevPos = {
                    x: res[0].changedTouches[0].clientX - rect.left,
                    y: res[0].changedTouches[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].changedTouches[0].clientX - rect.left,
                    y: res[1].changedTouches[0].clientY - rect.top
                };

                this.drawOnCanvas(prevPos, currentPos);

            });


        observableFromEvent(canvasEl, 'mousedown').pipe(
            switchMap((e) => {
                return observableFromEvent(canvasEl, 'mousemove').pipe(
                    takeUntil(observableFromEvent(canvasEl, 'mouseup')),
                    pairwise());
            }))
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();

                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                this.drawOnCanvas(prevPos, currentPos);
            });
    }

    private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
        if (!this.cx) {
            return;
        }

        this.cx.beginPath();

        if (prevPos) {
            this.cx.moveTo(prevPos.x, prevPos.y); // from
            this.cx.lineTo(currentPos.x, currentPos.y);
            this.cx.stroke();
        }

        this.image = this.canvasEl.toDataURL('image/jpeg');
        this.fileData.emit(this.image);
    }

    public clear() {

        this.cx.clearRect(0, 0, this.width, this.height);
        this.fileData.emit('');
    }
}
