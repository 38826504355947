import {Injectable} from '@angular/core';
import {DirectLine} from 'botframework-directlinejs';
import {environment} from '../../../environments/environment';
import {MessageItem} from '../entities/message';
import {Subject} from 'rxjs';
import 'rxjs-compat/add/observable/of';
import {Formfields} from '../config/formfields';

@Injectable()
export class SocketService {


    private botConnection: DirectLine;

    private _formfields: any = Formfields;
    public formfields$ = new Subject<Array<any>>();

    private _messages: MessageItem[] = [];
    public messages$ = new Subject<MessageItem>();

    private user = {name: 'Baloise User', id: Math.random().toString(36).substring(7), 'avatar': 'fsdfsdffsdfsdf'};


    constructor() {
        this.botConnection = new DirectLine({secret: environment.chatbot.secret});
        this.botConnection.activity$.subscribe(data => {
            if (data.from.id !== this.user.id) {

                switch (data.type) {
                    case 'message':
                        const newMessage = new MessageItem(data);
                        this._messages.push(newMessage);
                        this.messages$.next(newMessage);
                        break;

                    case 'event':
                        const newEvent = new MessageItem(data);
                        // if ((newMessage.entities) && (data.entities[0])) {
                        this.updateFormfields(newEvent.entities[0]);
                        // }
                        break;
                }
            }
        });
    }


    public getMessages() {
        return this.messages$;
    }

    public getFormfields() {
        return this.formfields$;
    }

    public getUser() {
        return this.user;
    }

    public updateFormfields(data) {

        this._formfields.map(block => {
            block.questions.map(question => {
                if (data[question.key]) {
                    question.value = data[question.key];
                }
                if ((data[block.title]) && (data[block.title][question.key])) {
                    question.value = data[block.title][question.key];
                }
            });
        });

        this.formfields$.next(this._formfields);
    }

    public sendMessage(message) {

        message.from = this.user;
        this.messages$.next(message);
        return this.botConnection.postActivity(this.parseToObject(message));
    }

    public sendEvent(message) {

        message.from = this.user;
        return this.botConnection.postActivity(this.parseToObject(message));
    }


    private parseToObject(json): any {

        if (json instanceof Array) {
            json.forEach((item, index) => {
                json[index] = this.parseToObject(item);
            });

        } else if (typeof json === 'object') {
            json = Object.assign({}, json);
            for (const key in json) {
                json[key] = this.parseToObject(json[key]);
            }
        }
        return json;
    }
}
