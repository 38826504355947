import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslationsService} from '../../services/translations.service';
import {TranslateService} from '@ngx-translate/core';
import {MessagingService} from '../../services/messaging.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: []
})
export class AppComponent implements OnInit {

    languages: string[];
    message: any;

    constructor(private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private translate: TranslateService,
                private translationService: TranslationsService,
                private messagingService: MessagingService) {

    }

    ngOnInit() {
        this.languages = this.translationService.getAvailableLanguages();
        this.translationService.setCurentLanguage('nl');

        const userId = 'user001';
        this.messagingService.requestPermission(userId);
        this.messagingService.receiveMessage();
        this.message = this.messagingService.currentMessage;
    }

    changeLanguage(lang) {
        this.translationService.setCurentLanguage(lang);
    }

    logout() {
        this.userService.logout().then(() => {
            this.router.navigate(['/']);
        });
    }
}
