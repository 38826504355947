import {IActivity, User} from 'botframework-directlinejs';
import {AdaptiveCard} from './adaptive-card';

export class MessageItem implements IActivity {
    id?: string;
    text: string;
    content: string;
    from: User;
    inputHint?: string;
    entities: any;
    type: string;
    attachments: Array<object>;
    value: any;
    locale: string;

    constructor(json: any) {
        this.text = json.text ? json.text : '';
        this.content = json.text ? json.text : '';
        this.from = json.from ? json.from : '';
        this.type = json.type ? String(json.type) : 'message';
        this.entities = json.entities;
        this.attachments = [];
        if (json.attachments) {
            json.attachments.forEach(att => {
                if ((att.content) && (att.content.type) && (att.content.type === 'AdaptiveCard')) {
                    this.attachments.push(new AdaptiveCard(att.content.body, att.content.actions));
                }
            });
        }
        this.locale = 'nl-BE';
    }

    addValue(value) {
        this.value = value;
    }
}

export enum InputType {
    CARROUSEL = 'carrousel',
    SLIDER = 'slider',
    DATEPICKER = 'datePicker',
    MONTH = 'month',
    LIST = 'list',
    TEXT = 'text',
    DONE = 'done'
}

