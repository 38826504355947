import {Component, OnInit} from '@angular/core';
import {GroupService} from '../../services/group.service';
import {Observable} from 'rxjs';
import {Group} from '../../entities/group';
import {map} from 'rxjs/operators';
import {UserService} from "../../../app/services/user.service";

@Component({
    selector: 'app-group-activity',
    templateUrl: './group-activity.component.html',
    styleUrls: ['./group-activity.component.scss']
})
export class GroupActivityComponent implements OnInit {


    public user;
    public groups: Observable<Group[]>;
    public total = {
        members: 0,
        candidates: 0
    };

    constructor(public groupsService: GroupService, public userService: UserService) {
        this.groups = this.groupsService.getGroups().pipe(map(groups => {
            return groups
                .sort((a, b) => (a.candidates.length > b.candidates.length) ? 1 : ((b.candidates.length > a.candidates.length) ? -1 : 0))
                .reverse();
        }));
    }

    ngOnInit() {
        this.user = this.userService.getUserId();

        this.groups.subscribe(groups => {
            this.total = {
                members: 0,
                candidates: 0
            };
            groups.forEach(group => {
                this.total.members += group.members.length;
                this.total.candidates += group.candidates.length;
            });
        });

    }

    newCandiates(group: Group) {
        if (group.candidates.length === 0) {
            return 'https://heartofmen.net/group/' + group.id;
        }

        if (group.candidates.length === 1) {
            return '%0D%0A%0D%0AEen tijdje geleden ' +
                'maakte je een mannengroep aan op https://heartofmen.net%0D%0ASuper tof. Mannen bijeen brengen is het doel van ons project ' +
                'en fijn dat je daaraan meehelpt. %0D%0A%0D%0AOp dit moment zit er een man die zich bij jouw mannengroep ' +
                'wil aansluiten te wachten op jouw goedkeuring.%0D%0ABekijk je dat effe op https://heartofmen.net/group/' + group.id +
                '%0D%0A%0D%0AThx.%0D%0AJelle';
        }

        return '%0D%0A%0D%0AEen tijdje geleden ' +
            'maakte je een mannengroep aan op https://heartofmen.net%0D%0ASuper tof. Mannen bijeen brengen is het doel van ons project en fijn ' +
            'dat je daaraan meehelpt. %0D%0A%0D%0AOp dit moment zitten er ' + group.candidates.length + ' mannen die zich bij jouw mannengroep ' +
            'willen aansluiten te wachten op jouw goedkeuring.%0D%0ABekijk je dat effe op https://heartofmen.net/group/' + group.id +
            '%0D%0A%0D%0AThx.%0D%0AJelle';

    }

    waitingForApproval(group: Group) {

        return '%0D%0A%0D%0AEen tijdje geleden registreerde je je op https://heartofmen.net%0D%0ASuper tof. Mannen bijeen brengen is' +
            ' het doel van ons project en fijn dat je van ons platform gebruik maakt. %0D%0A%0D%0ADe mannengroep waarvoor jij je inschreef ' +
            'blijkt niet actief te zijn. Jammer. Maar daarom hebben we je in een anderen mannengroep in de buurt geplaatst. Is dat ok? ' +
            '%0D%0ABekijk je dat effe op https://heartofmen.net/group/' + group.id + '%0D%0A%0D%0AThx.%0D%0AJelle';
    }


    mailToMembers(group: Group) {

        return '%0D%0A%0D%0AEen tijdje geleden registreerde je je op https://heartofmen.net%0D%0ASuper tof. Mannen bijeen brengen is' +
            ' het doel van ons project en fijn dat je van ons platform gebruik maakt. %0D%0A%0D%0A %0D%0A%0D%0A ' +
            '%0D%0ABekijk je dat effe op https://heartofmen.net/group/' + group.id + '?%0D%0A%0D%0AThx.%0D%0AJelle';
    }


}
