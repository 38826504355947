import {Injectable} from '@angular/core';
import {Group} from '../entities/group';
import {Address} from '../entities/address';
import {Observable, of} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {LocationService} from './location.service';
import * as firebase from 'firebase/app';
import {AlertService} from '../../app/services/alert.service';
import {Page} from '../../cms/entities/page';
import {AngularFireAuth} from '@angular/fire/auth';
import {map, take} from 'rxjs/operators';


@Injectable()
export class GroupService {


  constructor(
    private db: AngularFirestore,
    private locationService: LocationService,
    private alertService: AlertService,
    private auth: AngularFireAuth) {
  }


  /**
   *    common group-functions
   */

  public getGroups(): Observable<Group[]> {

    return this.db.collection<Page>('groups', ref => ref.orderBy('location.lat', 'desc')).valueChanges().map(data => {
      const groups = [];
      data.forEach(group => groups.push(new Group(group)));
      return groups;
    });
  }

  public getNumberOfGroups(): Observable<number> {
    return this.getGroups().pipe(map((data: Group[]) => data.length));
  }

  public getGroup(groupid: string): Observable<Group> {
    if (!groupid.length) {
      return of(new Group());
    }
    return this.db.collection('groups').doc(groupid).valueChanges().map(data => {
      return new Group(data);
    });
  }

  public createGroup(group: Group, adminId: string) {
    this.db.collection<any>('groups/').add(this.parseToObject(group)).then(data => {
      this.db.doc('groups/' + data.id).update({'id': data.id, organisers: [adminId], members: [adminId]});
      this.db.doc('users/' + adminId).update({'groups': firebase.firestore.FieldValue.arrayUnion(data.id)});

      this.alertService.createAlert(
        'XaBcofu8pTMUO5qfWcKVlTpxQDH3',
        'new group',
        '\'' + group.name + '\' has been created',
        '/group/' + data.id
      );
    });
  }


  /**
   *    Get and edit properties of group
   */

  public getAddress(groupid: string): Observable<Address> {

    let location: Address;

    this.getGroup(groupid).subscribe(data => {
      this.locationService.Lonlat2Address(data.location).subscribe(data2 => {
        location = data2;
      });
    });

    return Observable.create(location);
  }

  public updateGroupName(groupid: string, name: string, adminid: string) {
    return this.getGroup(groupid).pipe(take(1)).subscribe(group => {
      const oldname = group.name;
      if (group.organisers.indexOf(adminid) > -1) {
        this.db.doc('groups/' + groupid).update({name}).then(() => {

          group.members.forEach(memberid => {
            this.alertService.createAlert(
              memberid,
              'Groupname changed',
              'The name of your group has changed from "' + oldname + '" to "' + name + '"',
              '/group/' + groupid,
              adminid
            );
          });

          return true;
        }, () => {
          return false;
        });
      }

    });
  }


  public updateGroupDescription(groupid: string, description: string, adminid: string) {
    this.getGroup(groupid).pipe(take(1)).subscribe(group => {
      if (group.organisers.indexOf(adminid) > -1) {
        this.db.doc('groups/' + groupid).update({description});
      }

    });
  }


  /**
   *    Roles
   */

  public isMember(group: Group, userid: string): boolean {
    return group.members.indexOf(userid) > -1;
  }

  public isOwner(group: Group, userid: string): boolean {
    return group.organisers.indexOf(userid) > -1;
  }

  public isCandidate(group: Group, userid: string): boolean {
    return group.candidates.indexOf(userid) > -1;
  }

  public askMembership(groupid: string, userid: string) {

    this.db.doc('groups/' + groupid)
      .update({'candidates': firebase.firestore.FieldValue.arrayUnion(userid)})
      .then(() => {

        this.getGroup(groupid).take(1).subscribe(groupData => {
          const group = new Group(groupData);
          group.organisers.forEach(organiser => {
            this.alertService.createAlert(
              organiser,
              'new candidate',
              'A new candidate for your group ' + group.name,
              '/group/' + group.id,
              userid);
          });
        });
      });
  }

  public removeMembership(groupid: string, userid: string) {

    this.db.doc('groups/' + groupid)
      .update({'candidates': firebase.firestore.FieldValue.arrayRemove(userid)})
      .then(() => {
        this.db.doc('groups/' + groupid)
          .update({'members': firebase.firestore.FieldValue.arrayRemove(userid)})
          .then(() => {
          });
      });

  }

  public removeMemberFromGroup(groupid: string, userid: string) {

    this.db.doc('groups/' + groupid)
      .update({'members': firebase.firestore.FieldValue.arrayRemove(userid)})
      .then(() => {

        this.db.doc('groups/' + groupid)
          .update({'candidates': firebase.firestore.FieldValue.arrayUnion(userid)})
          .then(() => {
          });
      });

  }

  public acceptMembership(groupid: string, userid: string) {

    this.db.doc('groups/' + groupid)
      .update({'members': firebase.firestore.FieldValue.arrayUnion(userid)})
      .then(() => {

        this.db.doc('groups/' + groupid)
          .update({'candidates': firebase.firestore.FieldValue.arrayRemove(userid)})
          .then(() => {
            this.createNewMemberAlert(groupid, userid);
          });
      });
  }


  /**
   *    Private functions
   */

  private createNewMemberAlert(groupid, userid) {
    this.auth.user.pipe(take(1)).subscribe(userData => {
      this.getGroup(groupid).pipe(take(1)).subscribe(groupData => {
        this.alertService.createAlert(
          userid,
          'new member',
          'You are accepted to the group ' + groupData.name, '/group/' + groupData.id,
          userData.uid
        );
        groupData.members.forEach(memberid => {
          this.alertService.createAlert(
            memberid,
            'new member',
            'A new member for your group ' + groupData.name, '/user/' + userid,
            userid
          );
        });
      });
    });
  }

  private parseToObject(json): any {


    if (json instanceof Array) {
      json.forEach((item, index) => {
        json[index] = this.parseToObject(item);
      });

    } else if (typeof json === 'object') {
      json = Object.assign({}, json);
      // tslint:disable-next-line:forin
      for (let key in json) {
        json[key] = this.parseToObject(json[key]);
      }
    }
    return json;
  }
}

