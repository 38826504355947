import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../services/user.service';
import 'rxjs-compat/add/operator/map';

@Injectable()
export class LoggedinGuard implements CanActivate {

    constructor(private userService: UserService, public router: Router) {


    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return this.userService.getUserAuth().pipe(map(e => {
            // console.log(e);
            if (e) {
                return true;
            } else {
                this.router.navigate(['/groepen']);
                return false;
            }
        }));
    }
}

