export class Attachment {

    id: string;
    name: string;
    size: number;
    downloadUrl: string;
    creationDate: number;
    extension: any;

    constructor(json: any) {
        this.id = json.id ? json.id : 'no_id';
        this.name = json.name ? json.name : 'no name.dat';
        this.size = json.size ? json.size : 0;
        this.downloadUrl = json.downloadUrl ? json.downloadUrl : 'https://google.be';
        this.creationDate = json.creationDate ? json.creationDate : 0;


        const regex = /(?:\.([^.]+))?$/;
        this.extension = regex.exec(this.name)[1].toLocaleLowerCase();
    }

}
