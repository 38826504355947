export const environment = {
    production: false,
    messaging: {
        key: 'BI2CYncbr-2toRqTOKqL04mlC4T1S2yXM95Of9Il506cNuFNt3oCdQjS-b_zjQP25zxZO0qxKNhdThOOPXeQ8mo'
    },
    firebase: {
        apiKey: "AIzaSyDybUmTOFh3zIjChFxo5Aqb-E0rMj5mlvI",
        authDomain: "heartofmen-15ce7.firebaseapp.com",
        databaseURL: "https://heartofmen-15ce7.firebaseio.com",
        projectId: "heartofmen-15ce7",
        storageBucket: "heartofmen-15ce7.appspot.com",
        messagingSenderId: "22128288867"
    },
    chatbot: {
        appId: '9bd40920-216b-41a6-9be6-c6c9a0778371',
        pwd: 'gKT8934}-nyljifIFYWS9:?',
        secret: 'DiJOsxcKxgE.cwA.p3Y.5q5i-1ibjoxzy3IoPRHbDu9hHGRwH4DrJ5i6IYYD_Y4'
    },
    maps: {
        key: 'AIzaSyDybUmTOFh3zIjChFxo5Aqb-E0rMj5mlvI'
    }
};
